<template>
   <div class="p-10 px-20">
      <template v-if="isLoading">
         <div class="h-96 text-center grid grid-cols-1 gap-4 place-content-center">
            <svg class="animate-spin h-8 w-8 mx-auto text-indigo-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
               <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
               <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
         </div>
      </template>
      <template v-else>
         <div class="flex justify-start">
            <router-link to="/account/user/transactions" class="bg-gray-300 hover:bg-gray-400 transition transition-colors p-2 rounded-full">
               <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
               </svg>
            </router-link>
         </div>
         <div class="text-lg border-b pt-8 pb-5 border-dashed mb-5 border-gray-300">
            <span class="font-semibold">Transaction Information | {{ transactionData.invoice_id }}</span>
         </div>
         <div class="grid grid-cols-5 gap-9">
            <div class="col-span-4 w-full mx-auto py-4 bg-white rounded-xl border px-10">
               <div class="text-base font-normal py-4">
                  <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                     <div class="">
                        Transaction Title:
                     </div>
                     <div class="font-medium">
                        {{ transactionData.service_name | capitalize }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                     <div class="">
                        Transaction ID:
                     </div>
                     <div class="font-medium">
                        {{ transactionData.invoice_id }}
                     </div>
                  </div>
                  <template v-if="transactionData.creator_role == 'buyer' && transactionData.creator_email == user.email">
                     <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                        <div class="">
                           Seller's Name:
                        </div>
                        <div class="font-medium">
                           {{ transactionData.invitee_name | capitalize }}
                        </div>
                     </div>
                     <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                        <div class="">
                           Seller's Email:
                        </div>
                        <div class="font-medium">
                           {{ transactionData.invitee_email }}
                        </div>
                     </div>
                     <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                        <div class="">
                           Creator's Phone:
                        </div>
                        <div class="font-medium">
                           {{ transactionData.invitee_phone }}
                        </div>
                     </div>
                  </template>
                  <template v-if="transactionData.creator_role == 'seller' && transactionData.creator_email == user.email">
                     <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                        <div class="">
                           Buyer's Name:
                        </div>
                        <div class="font-medium">
                           {{ transactionData.invitee_name | capitalize }}
                        </div>
                     </div>
                     <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                        <div class="">
                           Buyer's Email:
                        </div>
                        <div class="font-medium">
                           {{ transactionData.invitee_email }}
                        </div>
                     </div>
                     <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                        <div class="">
                           Buyer's Phone:
                        </div>
                        <div class="font-medium">
                           {{ transactionData.invitee_phone }}
                        </div>
                     </div>
                  </template>
                  <template v-if="transactionData.creator_role == 'buyer' && transactionData.creator_email != user.email">
                     <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                        <div class="">
                           Buyer's Name:
                        </div>
                        <div class="font-medium">
                           {{ transactionData.creator_name | capitalize }}
                        </div>
                     </div>
                     <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                        <div class="">
                           Buyer's Email:
                        </div>
                        <div class="font-medium">
                           {{ transactionData.creator_email }}
                        </div>
                     </div>
                     <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                        <div class="">
                           Buyer's Phone:
                        </div>
                        <div class="font-medium">
                           {{ transactionData.creator_phone }}
                        </div>
                     </div>
                  </template>
                  <template v-if="transactionData.creator_role == 'seller' && transactionData.creator_email != user.email">
                     <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                        <div class="">
                           Seller's Name:
                        </div>
                        <div class="font-medium">
                           {{ transactionData.creator_name | capitalize }}
                        </div>
                     </div>
                     <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                        <div class="">
                           Seller's Email:
                        </div>
                        <div class="font-medium">
                           {{ transactionData.creator_email }}
                        </div>
                     </div>
                     <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                        <div class="">
                           Seller's Phone:
                        </div>
                        <div class="font-medium">
                           {{ transactionData.creator_phone }}
                        </div>
                     </div>
                  </template>
                  <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                     <div class="">
                        My Role:
                     </div>
                     <div class="font-medium">
                        <span v-if="transactionData.creator_email == user.email">{{ transactionData.creator_role | capitalize }}</span>
                        <span v-else>
                           <span v-if="transactionData.creator_role == 'buyer'">Seller</span>
                           <span v-else>Buyer</span>
                        </span>
                     </div>
                  </div>
                  <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                     <div class="">
                        Transaction Category:
                     </div>
                     <div class="font-medium">
                        {{ transactionData.category | capitalize }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                     <div class="">
                        Amount:
                     </div>
                     <div class="font-medium">
                        <span v-if="transactionData.currency == 'usd'">&#36;{{ transactionData.price | formatPrice }}</span>
                        <span v-if="transactionData.currency == 'ngn'">&#8358;{{ transactionData.price | formatPrice }}</span>
                        <span v-if="transactionData.currency == 'cny'">&#165;{{ transactionData.price | formatPrice }}</span>
                        <span v-if="transactionData.currency == 'usdt'">{{ transactionData.price | formatPrice }} USDT</span> 
                        <span v-if="transactionData.currency == 'busd'">{{ transactionData.price | formatPrice }} BUSD</span>
                     </div>
                  </div>
                  <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                     <div class="">
                        Additional Description:
                     </div>
                     <div class="font-medium">
                        {{ transactionData.description | capitalize }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                     <div class="">
                        Date Created:
                     </div>
                     <div class="font-medium">
                        {{ transactionData.created_at | formatDate }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                     <div class="">
                        Estimated Completion Date
                     </div>
                     <div class="font-medium">
                        {{ transactionData.duration | formatDate }}
                     </div>
                  </div>
                  <div class="flex justify-between pb-3 mb-3 border-b border-gray-100">
                     <div class="">
                        Status
                     </div>
                     <div class="font-medium">
                        {{ transactionData.status | capitalize }}
                     </div>
                  </div>
               </div>
            </div>
            <div class="my-10">
               <div class="grid grid-cols-1 gap-5" v-if="transactionData.invitee_email == user.email">
                  <div>
                     <button type="button" @click="transactionData.status != 'accepted' && transactionData.status != 'confirmed' && transactionData.status != 'rejected' ? acceptTransaction() : null" :class="transactionData.status == 'accepted' || transactionData.status == 'confirmed' || transactionData.status == 'rejected' ? 'bg-green-200 border-green-300 text-green-600 hover:bg-green-300 hover:border-green-400 cursor-not-allowed' : 'bg-green-500 border-green-600 hover:bg-green-600 hover:border-green-700'"
                        class="inline-block px-6 py-2.5 border text-white font-medium text-xs leading-tight uppercase rounded focus:ring-0 transition duration-150 ease-in-out">
                        Accept<span v-show="transactionData.status == 'accepted'">ed</span>
                     </button>
                  </div>
                  <div> 
                     <button type="button" 
                        @click="transactionData.status != 'accepted' && transactionData.status != 'confirmed' && transactionData.status != 'rejected' ? delcineTransaction() : null"
                        :class="transactionData.status == 'accepted' || transactionData.status == 'confirmed' || transactionData.status == 'rejected' ? 'bg-red-100 border-red-200 text-red-600 hover:bg-red-200 hover:border-red-300 cursor-not-allowed' : 'bg-red-500 border-red-600 hover:bg-red-600 hover:border-red-700'"
                        class="inline-block px-6 py-2.5 border text-white font-medium text-xs leading-tight uppercase rounded focus:ring-0 transition duration-150 ease-in-out"
                        data-bs-dismiss="modal">
                        Decline<span v-show="transactionData.status == 'rejected'">d</span>
                     </button>
                  </div>
                  <div>
                     <button type="button" @click="transactionData = null"
                        class="inline-block px-6 py-2.5 bg-yellow-500 border border-yellow-700 text-white font-medium text-xs leading-tight uppercase rounded hover:bg-red-600 hover:border-red-700 focus:ring-0 transition duration-150 ease-in-out"
                        data-bs-dismiss="modal">
                        Request Edit
                     </button>
                  </div>
               </div>
               <div v-if="transactionData.status == 'accepted'" class="grid grid-cols-1 gap-5">
                  <div>
                     <button type="button" data-bs-toggle="modal" :data-bs-target="transactionData.status != 'paid' ? '#pay' : null" :class="transactionData.status == 'paid' ? 'bg-indigo-300 hover:bg-indigo-400 text-white cursor-not-allowed' : 'bg-indigo-900 hover:bg-indigo-800'"
                        class="inline-block px-8 py-2.5 border text-white font-medium text-xs leading-tight uppercase rounded focus:ring-0 transition duration-150 ease-in-out">
                        <span v-if="transactionData.status != 'paid'">Pay</span><span v-else>Paid</span>
                     </button>
                  </div>
                  <div>
                     <router-link to="/account/user/transactions" type="button" class="inline-block bg-gray-300 px-7 py-2.5 font-medium text-xs leading-tight 
                        uppercase rounded focus:ring-0 transition duration-150 ease-in-out">
                        Back
                     </router-link>
                  </div>
               </div>
            </div>
         </div>
      </template>

      <!-- Modals -->
      <div class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto" id="pay" 
         data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="payTitle" aria-modal="true" aria-hidden="true" role="dialog">
         <div class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none">
            <div class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
               <div class="modal-header flex flex-shrink-0 items-center justify-between p-4 px-8 border-b border-gray-100 rounded-t-md">
                  <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalScrollableLabel">
                     Pay for Product
                  </h5>
               </div>
               <div class="modal-body relative px-8 py-5">
                  <template v-if="selectedMethod == ''">
                     <div class="text-lg mb-5">
                        How would you want to pay?
                     </div>
                     <div class="grid grid-cols-2 gap-5">
                        <div @click="selectedMethod = 'wallet'" class="bg-gray-50 text-center rounded-md py-10 text-gray-500 cursor-pointer hover:shadow-md transition duration-150 ease-in-out">
                           <div class="uppercase text-sm">
                              Pay from {{ transactionData.currency | ucwords }} balance
                           </div>
                           <div class="text-xl font-semibold text-indigo-900 mt-5">
                              <p v-if="transactionData.currency == 'usd'">&#36;{{ user.wallets.usd_balance || 0 | formatPrice }}</p>
                              <p v-if="transactionData.currency == 'ngn'">&#8358;{{ user.wallets.ngn_balance || 0 | formatPrice }}</p>
                              <p v-if="transactionData.currency == 'cny'">&#165;{{ user.wallets.cny_balance || 0 | formatPrice }}</p>
                              <p v-if="transactionData.currency == 'usdt'">{{ user.wallets.usdt_balance }} USDT</p>
                              <p v-if="transactionData.currency == 'busd'">{{ user.wallets.busd_balance }} BUSD</p>
                           </div>
                           
                           <!--div class="text-xl font-semibold text-indigo-900 mt-5">
                              <span v-if="transactionData.currency == 'usd'">{{ transactionData.currency | ucwords }}</span>
                              <span v-if="transactionData.currency == 'ngn'">{{ transactionData.currency | ucwords }}</span>
                              <span v-if="transactionData.currency == 'cny'">{{ transactionData.currency | ucwords }}</span>
                              <span v-if="transactionData.currency == 'usdt'">{{ transactionData.currency | ucwords }}</span> 
                              <span v-if="transactionData.currency == 'busd'">{{ transactionData.currency | ucwords }}</span> Wallet</div-->
                        </div>
                        <div @click="selectedMethod = 'paystack'" class="bg-gray-50 text-center rounded-md px-5 text-gray-500 cursor-pointer hover:shadow-md transition duration-150 ease-in-out">
                           <img src="@/assets/logos/paystack-logo-vector.svg" alt="" class="mx-auto">
                        </div>
                     </div>
                  </template>
                  <template v-if="selectedMethod == 'wallet'">

                  </template>
                  <template v-if="selectedMethod == 'paystack'">
                     <div class="bg-gray-50 rounded-md p-5 mb-5 text-sm text-gray-500">
                        <strong>Note:</strong> You will be charged an extra transaction fee of N100 + 1.5% of your Naira deposit amount. 
                     </div>
                     <div class="mt-5 text-lg font-semibold border-b border-gray-100 mb-5 pb-3">
                        Payment Summery
                     </div>
                     <div class="flex justify-between mb-3">
                        <div>
                           Amount:
                        </div>
                        <div>
                           <span v-if="transactionData.currency == 'usd'">&#36;{{ transactionData.price | formatPrice }}</span>
                           <span v-if="transactionData.currency == 'ngn'">&#8358;{{ transactionData.price | formatPrice }}</span>
                           <span v-if="transactionData.currency == 'cny'">&#165;{{ transactionData.price | formatPrice }}</span>
                           <span v-if="transactionData.currency == 'usdt'">{{ transactionData.price | formatPrice }} USDT</span> 
                           <span v-if="transactionData.currency == 'busd'">{{ transactionData.price | formatPrice }} BUSD</span>
                        </div>
                     </div>
                     <div class="flex justify-between mb-5">
                        <div>
                           Fee:
                        </div>
                        <div>
                           <span v-if="transactionData.currency == 'usd'">&#36;{{ paytack_ngn_fee | formatPrice }}</span>
                           <span v-if="transactionData.currency == 'ngn'">&#8358;{{ paytack_ngn_fee | formatPrice }}</span>
                           <span v-if="transactionData.currency == 'cny'">&#165;{{ paytack_ngn_fee | formatPrice }}</span>
                           <span v-if="transactionData.currency == 'usdt'">{{ paytack_ngn_fee | formatPrice }} USDT</span> 
                           <span v-if="transactionData.currency == 'busd'">{{ paytack_ngn_fee | formatPrice }} BUSD</span>
                        </div>
                     </div>
                     <div class="flex justify-between mt-3 border-t border-gray-100 pt-3">
                        <div class="font-semibold text-lg">
                           Total
                        </div>
                        <div>
                           <span v-if="transactionData.currency == 'usd'">&#36;{{ paytack_ngn_fee + transactionData.price | formatPrice }}</span>
                           <span v-if="transactionData.currency == 'ngn'">&#8358;{{ paytack_ngn_fee + transactionData.price | formatPrice }}</span>
                           <span v-if="transactionData.currency == 'cny'">&#165;{{ paytack_ngn_fee + transactionData.price | formatPrice }}</span>
                           <span v-if="transactionData.currency == 'usdt'">{{ paytack_ngn_fee + transactionData.price | formatPrice }} USDT</span> 
                           <span v-if="transactionData.currency == 'busd'">{{ paytack_ngn_fee + transactionData.price | formatPrice }} BUSD</span>
                        </div>
                     </div>
                  </template>
               </div>
               <div class="modal-footer border-top relative px-8 py-5">
                  <div class="flex justify-end space-x-3">
                     <Paystack v-if="selectedMethod == 'paystack'"
                        :amount="(paytack_ngn_fee + transactionData.price) * 100"
                        :email="user.email"
                        :paystackkey="form.paystackkey"
                        :reference="reference"
                        :callback="process_ngn_Payment"
                        :channels="form.channels"
                        :firstname="user.account.first_name | capitalize"
                        :lastname="user.account.last_name | capitalize"
                        :close="close">
                           <button class="uppercase text-sm inline-block px-10 py-2.5 text-white bg-indigo-900 hover:bg-indigo-800 font-medium text-xs leading-tight uppercase rounded focus:ring-0 transition duration-150 ease-in-out" type="submit">
                           <svg v-if="isLoading" class="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                           </svg> Pay 
                        </button>
                     </Paystack>
                     <button type="button" ref="Close" @click="selectedMethod = ''" data-bs-dismiss="modal" aria-label="Close" class="uppercase text-sm inline-block px-6 py-2.5 text-white bg-red-500 hover:bg-red-600 font-medium text-xs leading-tight uppercase rounded focus:ring-0 transition duration-150 ease-in-out">
                        Cancel
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
   data() {
      return {
         isLoading: true,
         transactionData: [],
         selectedMethod: '',
         form: {
            paystackkey: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY,
            currency: 'NGN',
            channels: ['card', 'bank', 'ussd'],
            transaction_ref: '',
         },
      }
   },

   methods: {
      ...mapActions('transactions', ['get_transaction_info', 'decline_transaction', 'accept_transaction', 'confirm_escrow']),

      async getTransactionData() {
         const endpoint_params = {
            transaction_id: this.$route.params.id
         }
         try {
            let res = await this.get_transaction_info(endpoint_params)
            this.transactionData = res.data
            this.isLoading = false
         } catch (error) {
            this.isLoading = true
            if(error.response.status == 403) {
               this.$toast.error(error.response.data.message)
               this.$router.push('/account/user/transactions')
            }
         }
      },

      async delcineTransaction() {
         const shouldDelete = await this.$swal.fire({
            title: 'Are you sure?',
            text: "You are about to decline this transaction, you won't be able to revert this action. Continue?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#312e81',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, decline transaction!'
         })
         if(shouldDelete.isConfirmed == true) {
            const endpoint_params = {
               transaction_id: this.$route.params.id
            }
            let res = await this.decline_transaction(endpoint_params)
            if (res.success == true) {
               this.$swal.fire(
                  'Transaction Declined!',
                  'You have declined this transaction successfully.',
                  'success'
               )
               this.$router.push({ name: 'Transactions' })
            }
         }
      },

      async acceptTransaction() {
         const shouldAccept = await this.$swal.fire({
            title: 'Accept transaction?',
            text: "Confirm that you want to accept this transaction",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#16a34a',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, accept transaction!'
         })

         if(shouldAccept.isConfirmed == true) {
            const endpoint_params = {
               transaction_id: this.$route.params.id
            }
            let res = await this.accept_transaction(endpoint_params)
            if (res.success == true) {
               this.$swal.fire(
                  'Transaction Accepted!',
                  'You have accepted this transaction successfully.',
                  'success'
               )
               this.getTransactionData()
            }
         }
      },

      async process_ngn_Payment() {

         const endpoint_params = {
            transaction_id: this.transactionData.id,
            amount: this.transactionData.price,
            charge: this.paytack_ngn_fee,
            gateway: "paystack",
            currency: this.transactionData.currency.toUpperCase(),
            status: 'success',
         }

         try{
            let res = await this.confirm_escrow(endpoint_params)
            
            this.$forceUpdate()
            this.$refs.Close.click();
            this.$swal.fire({
               title: "Success",
               text: res.message,
               icon: 'success',
               showConfirmButton: false,
               showCancelButton: false,
               timer: 1500
            })
            //this.$toast.success(res.message)
            this.$router.push({ name: 'Transactions' })
         } catch(error) {
            this.$toast.error(error.response.data.message)
         }
      },

      close() {
         
      }
   },

   computed: {
      ...mapGetters('auth', ['user']),

      paytack_ngn_fee: function() {
         let percent =  (0.015 * parseFloat(this.transactionData.price)) + 100 // 1.5% by amount
         
         return parseFloat(percent.toFixed(2))
      },

      reference() {
         const d = new Date();
         let seconds = d.getSeconds();
         let day = d.getDate();
         let month = d.getMonth() + 1;
         let year = (d.getFullYear() + '').slice(-2);

         let ref = 'TX-NGN-' + seconds + day + month + year

         return ref;
      },
   },

   mounted() {
      this.getTransactionData()
   }
}
</script>